<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class='col'>
          <h5 class="page-header">
            Edit Tag: {{ editingTag.name }}
          </h5>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <!-- Loading spinner -->
      <div
        v-if="$apollo.loading"
        class="position-fixed end-0 w-100 justify-content-center"
        style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
      >
        <div class="text-center col pt-5 mt-5">
          <BaseSpinner />
          <p class="d-block lead fs-4 mt-5">Loading tag data</p>
        </div>
      </div>
      <div v-else class='row px-5 pt-4'>
        <div class="col col-md-8 col-lg-6 col-xl-4">
          <ValidationObserver ref='form' v-slot="{  }" >
            <form @submit.prevent="createTag">
              <h5>{{ editingTag.type ? editingTag.type.label : "Loading" }}</h5>

              <p class="mt-3 mb-5 lead">{{typeCategoryInfoBlurb}}</p>
              
              <BaseAlert v-if="alert.type" class="mb-4" :type="alert.type">
                <span slot="title">{{ alert.title }}</span>
                <ul v-if="typeof(alert.message) === 'object'" slot="message">
                  <li v-for="m in alert.message" :key="m">{{ m }}</li>
                </ul>
                <span v-else slot="message">{{ alert.message }}</span>
              </BaseAlert>


              <BaseFormGroup v-if='presentFields.label' :label="'Name'" >
                <ValidationProvider v-slot="{ errors }" name='Tag Name' rules='required' >
                  <BaseInput v-model="editingTag.label" type="text" name="name" :error="errors[0]" :disabled='!enabledFields.label' />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup v-if='presentFields.type' :label="'Tag Type'" >
                <ValidationProvider v-slot="{ errors }" name='Tag Types' rules='required'>
                  <BaseMultiselect v-model="editingTag.type" :options="tagTypes" :multiple="false" :error="errors[0]" track-by="name" name="tagTypes" :disabled='!enabledFields.type' @input="handleTypeChange" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup v-if='presentFields.categories' :label="'Categories'" >
                <ValidationProvider v-slot="{ errors }" name='Tag Categories' rules='required'>
                  <BaseMultiselect v-model="editingTag.categories" :options="validCategories" :multiple="true" :error="errors[0]" track-by="name" name="tagCategories" :disabled='!enabledFields.categories' />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup v-if='presentFields.color' :label="'Colour'" >
                <ValidationProvider name='Tag Colour'>
                  <!-- <BaseMultiselect v-model="editingTag.color" :options="availableColors" :error="errors[0]" name="color" :disabled='!enabledFields.color' /> -->
                  <BaseColorPicker v-model="editingTag.color" :disabled='!enabledFields.color' />
                </ValidationProvider>
              </BaseFormGroup>

              <!-- Channel Stuff -->
              <BaseFormGroup v-if="presentFields.hiddenInFilters" :label="'Hidden in filter'">
                <ValidationProvider v-slot="{ errors }" name='hiddenInFilters' >
                  <BaseInput v-model="editingTag.hiddenInFilters" type="checkbox" name="hiddenInFilters" :error="errors[0]" :disabled='!enabledFields.hiddenInFilters'></BaseInput>
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup v-if="presentFields.hiddenInActivities" :label="'Hidden in activities'">
                <ValidationProvider v-slot="{ errors }" name='hiddenInActivities' >
                  <BaseInput v-model="editingTag.hiddenInActivities" type="checkbox" name="hiddenInActivities" :error="errors[0]" :disabled='!enabledFields.hiddenInActivities'></BaseInput>
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup v-if="presentFields.isExternal" :label="'Is an external channel of communication'">
                <ValidationProvider v-slot="{ errors }" name='isExternal' >
                  <BaseInput v-model="editingTag.isExternal" type="checkbox" name="isExternal" :error="errors[0]" :disabled='!enabledFields.isExternal'></BaseInput>
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup v-if="presentFields.isGroupedOnCalendar" :label="'Channel is grouped on the calendar'">
                <ValidationProvider v-slot="{ errors }" name='isGroupedOnCalendar' >
                  <BaseInput v-model="editingTag.isGroupedOnCalendar" type="checkbox" name="isGroupedOnCalendar" :error="errors[0]" :disabled='!enabledFields.isGroupedOnCalendar'></BaseInput>
                </ValidationProvider>
              </BaseFormGroup>

              <!-- Location stuff -->
              <BaseFormGroup v-if='presentFields.shortName' :label="'Short Name'" >
                <ValidationProvider v-slot="{ errors }" name='shortName' rule='required' >
                  <BaseInput v-model="editingTag.shortName" type="text" name="shortName" :error="errors[0]" :disabled='!enabledFields.shortName' />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup v-if='presentFields.parentLocation' :label="'Parent Location'" >
                <ValidationProvider v-slot="{ errors }" name='Tag Parent Location'>
                  <BaseMultiselect v-model="editingTag.parentLocation" :options="tagParentLocations" :error="errors[0]" track-by="name" name="tagParentLocation" :disabled='!enabledFields.parentLocation' />
                </ValidationProvider>
              </BaseFormGroup>
        
              <BaseFormGroup v-if='presentFields.subLocations' :label="'Child Locations'" >
                <ValidationProvider v-slot="{ errors }" name='Tag Children Locations'>
                  <BaseMultiselect v-model="editingTag.subLocations" :options="tagChildLocations"  :multiple="true" :error="errors[0]" track-by="name" name="tagChildrenLocations" :disabled='!enabledFields.subLocations' />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup class=" mt-5 mx-5">
                <BaseButton type="submit" :disabled="isLoading" :classes="'btn-success'" >
                  <span>Save</span>
                </BaseButton>

                <BaseButton class="btn-secondary mx-5 px-4" :disabled="isLoading" type="button" @click="$router.push({name: 'tags'})">
                  <span>Back</span>
                </BaseButton>
              </BaseFormGroup>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <div v-if="user.role.name === 'SYSADMIN'" class="row mt-5">
        <div class="col">
          <div class='card'>
            <div class='card-body bg-light'>
              <h5 class="card-title">SYSADMIN debug</h5>
              <p class='card-text'>
                User:
                <pre><code class="text-break">{{ editingTag }}</code></pre>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import Layout from '@layouts/main'
import {
  LocalGetSelf,
} from '@gql/user'
import {    
  CreateTag,
  GetTags
} from '@gql/tag'
import {
  GetTagTypes,
  GetTagCategories,
} from '@gql/tagTypes'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

import { snakeCase, orderBy } from 'lodash'

export default {
  page() {
    const name = this.editingTag ? this.editingTag.name : 'Tag'

    return {
      title: name,
      meta: [
        {
          name: 'description',
          content: `Edit ${name}.`,
        },
      ],
    }
  },
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      alert: {},
      editingTag: {
        color: null,
        type: null,
        categories: [],
        label: null,
      },
      presentFields: {
        type: true,
        label: true,
        categories: true,
        color: true,

        hiddenInFilters: false,
        hiddenInActivities: false,
        isExternal: false,
        isGroupedOnCalendar: false,

        userLink: false,

        groupLink: false,

        shortName: false,
        parentLocation: false,
        subLocations: false,
      },
      enabledFields: {
        type: true,
        label: true,
        categories: false,
        color: true,

        hiddenInFilters: false,
        hiddenInActivities: false,
        isExternal: false,
        isGroupedOnCalendar: false,

        userLink: false,

        groupLink: false,

        shortName: false,
        parentLocation: false,
        subLocations: false,
      }
    }
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      update(data) {
        if(!data.user.isAdminUser) { this.$router.push({ name: '403', params: { resource: 'tags.edit' }}) }
        return data.user
      }
    },
    tagParentLocations: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "location" } },
            categories: { some: { name: { equals: "region" } } }
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Regions from API: ", data);
        return data.tags;
      },
    },
    tagTypes: {
      query: GetTagTypes,
      variables() {
        return {
          companyId: this.user.company.id 
        };
      },
      update(data) {
        this.$log.debug(data); 
        return orderBy(data.tagTypes, ["label"])
      }
    },
    tagCategories: {
      query: GetTagCategories,
      variables() {
        return {
          companyId: this.user.company.id 
        };
      },
      update(data) {
        this.$log.debug(data); 
        return orderBy(data.tagCategories, ["label"])
      },
      result() {
        this.editingTag.categories = this.tagCategories.filter(category => (category.name === 'tag'))
      }
    },
  },
  computed: {
    typeCategoryInfoBlurb() {
      if (!this.editingTag.type) return "";
      if (this.editingTag.type.name === "channel") {
        return "Creating a channel."
      } else if (this.editingTag.type.name === "generic") {
        return "Creating a tag."
      } else if (this.editingTag.type.name === "location") {
        return "Creating a location tag."
      }

      return "This tag is of an unknown type, so editing has been disabled. Please contact jadepacific for assistance if you believe this is an error."
    },
    validCategories() {
      if (!this.tagCategories || !this.editingTag) {
        return []
      }
      return this.tagCategories.filter((category) => (category.type.id === this.editingTag.type.id))
    }
  },
  watch: {
    editingTag: function () {
      if (!this.editingTag.type) {
        this.enabledFields = {label: true, type: true, categories: false, color: false}
        this.presentFields = {label: true, type: true, categories: false, color: false}
      } else {
        this.enabledFields = {
          type: true,
          label: this.editingTag.type.name !== "person" && this.editingTag.type.name !== "group",
          categories: this.editingTag.type.name !== "channel" && this.editingTag.type.name !== "group",
          color: this.editingTag.type.name === "channel",

          hiddenInFilters: this.editingTag.type.name === "channel",
          hiddenInActivities: this.editingTag.type.name === "channel",
          isExternal: this.editingTag.type.name === "channel",
          isGroupedOnCalendar: this.editingTag.type.name === "channel",

          userLink: this.editingTag.type.name === "person",

          groupLink: this.editingTag.type.name === "group",

          shortName: this.editingTag.type.name === "location",
          parentLocation: this.editingTag.type.name === "location" && this.editingTag.categories.filter(category => (category.name === "location")).length > 0,
          subLocations: false,
        }
        this.presentFields = {
          type: true,
          label: true,
          categories: this.editingTag.type.name !== "channel" && this.editingTag.type.name !== "group",
          color: true,

          hiddenInFilters: this.editingTag.type.name === "channel",
          hiddenInActivities: this.editingTag.type.name === "channel",
          isExternal: this.editingTag.type.name === "channel",
          isGroupedOnCalendar: this.editingTag.type.name === "channel",

          userLink: this.editingTag.type.name === "person",

          groupLink: this.editingTag.type.name === "group",

          shortName: this.editingTag.type.name === "location",
          parentLocation: this.editingTag.type.name === "location",
          subLocations: this.editingTag.type.name === "location",
        }
      }
      this.$log.debug("Editing tag")
      this.$log.debug(JSON.parse(JSON.stringify(this.presentFields)))
    },
    tagTypes: function() {
      if (!this.editingTag.type) {
        this.editingTag.type = this.tagTypes.find(type => (type.name === 'generic'))
      }
    },
  },
  methods: {
    async createTag() {
      this.isLoading = true
      this.alert = {}

      // Validate the form
      if(!await this.$refs.form.validate()) {
        this.isLoading = false
        this.alert.type = 'error'
        this.alert.title = 'Error'
        this.alert.message = 'Please check the form below for errors'
        return false
      }
      // Validation good, reset
      this.$refs.form.reset()

      // Generate data
      const data = {
        label: this.editingTag.label,
        name: snakeCase(this.editingTag.label),
        company: { connect: { id: this.user.company.id } },
        type: { connect: { id: this.editingTag.type.id } }
      }
      if (this.enabledFields.categories && this.editingTag.categories) data.categories = { connect: this.editingTag.categories.map(category => ({id: category.id})) }
      if (this.enabledFields.color && this.editingTag.color) data.color = this.editingTag.color

      if (this.enabledFields.hiddenInFilters) data.hiddenInFilters = this.editingTag.hiddenInFilters
      if (this.enabledFields.hiddenInActivities) data.hiddenInActivities = this.editingTag.hiddenInActivities
      if (this.enabledFields.isExternal) data.isExternal = this.editingTag.isExternal
      if (this.enabledFields.isGroupedOnCalendar) data.isGroupedOnCalendar = this.editingTag.isGroupedOnCalendar

      if (this.enabledFields.shortName && this.editingTag.shortName) data.shortName = this.editingTag.shortName
      if (this.enabledFields.parentLocation && this.editingTag.parentLocation) data.parentLocation = { connect: { id: this.editingTag.parentLocation.id } }

      // Not editable for now
      // if (this.enabledFields.userLink && this.editingTag.userLink) data.userLink = this.editingTag.userLink
      // if (this.enabledFields.groupLink && this.editingTag.groupLink) data.groupLink = this.editingTag.groupLink
      // if (this.enabledFields.subLocations && this.editingTag.subLocations) data.subLocations = { connect: this.editingTag.subLocations.map(location => ({id: location.id})) }

      this.$apollo.mutate({
        mutation: CreateTag,
        variables: {
          data: data
        },
        update: (store, { data: { updateTag }}) => {},
        error (err) {
          this.$log.error(err)
          this.alert.type = 'error'
          this.alert.title = 'There was a problem'
          this.alert.message = err
        },
      }).then(data => {
        this.alert.type = 'success'
        this.alert.title = 'Success'
        this.alert.message = 'Tag updated'
        this.isLoading = false
        this.$refs.form.reset()
        // Redirect
        setTimeout(() => { this.$router.push({name: 'tags'}) }, 1000)
      }).catch((err) => {
        // Error
        this.alert.type = 'error'
        this.alert.title = 'There was a problem'
        this.alert.message = err
      })
    },
    handleTypeChange(params) {
      if (params.name === 'generic') {
        this.editingTag.categories = this.tagCategories.filter(category => (category.name === 'tag'))
        this.editingTag.color = this.editingTag.type.color
      } else if (params.name === 'location') {
        this.editingTag.categories = this.tagCategories.filter(category => (category.name === 'location'))
        this.editingTag.color = this.editingTag.type.color
      } else if (params.name === 'channel') {
        this.editingTag.categories = this.tagCategories.filter(category => (category.name === 'channel'))
        this.editingTag.color = this.editingTag.type.color
      }


      if (!this.editingTag.type) {
        this.enabledFields = {label: true, type: true, categories: false, color: false}
        this.presentFields = {label: true, type: true, categories: false, color: false}
      } else {
        this.enabledFields = {
          type: true,
          label: this.editingTag.type.name !== "person" && this.editingTag.type.name !== "group",
          categories: this.editingTag.type.name !== "channel" && this.editingTag.type.name !== "group",
          color: this.editingTag.type.name === "channel",

          hiddenInFilters: this.editingTag.type.name === "channel",
          hiddenInActivities: this.editingTag.type.name === "channel",
          isExternal: this.editingTag.type.name === "channel",
          isGroupedOnCalendar: this.editingTag.type.name === "channel",

          userLink: this.editingTag.type.name === "person",

          groupLink: this.editingTag.type.name === "group",

          shortName: this.editingTag.type.name === "location",
          parentLocation: this.editingTag.type.name === "location" && this.editingTag.categories.filter(category => (category.name === "location")).length > 0,
          subLocations: false,
        }
        this.presentFields = {
          type: true,
          label: true,
          categories: this.editingTag.type.name !== "channel" && this.editingTag.type.name !== "group",
          color: true,

          hiddenInFilters: this.editingTag.type.name === "channel",
          hiddenInActivities: this.editingTag.type.name === "channel",
          isExternal: this.editingTag.type.name === "channel",
          isGroupedOnCalendar: this.editingTag.type.name === "channel",

          userLink: this.editingTag.type.name === "person",

          groupLink: this.editingTag.type.name === "group",

          shortName: this.editingTag.type.name === "location",
          parentLocation: this.editingTag.type.name === "location",
          subLocations: this.editingTag.type.name === "location",
        }
      }
    }
  }
}
</script>