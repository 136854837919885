var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"container-fluid pt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"page-header"},[_vm._v(" Edit Tag: "+_vm._s(_vm.editingTag.name)+" ")])])]),_c('hr',{staticClass:"dotted-spacer"}),(_vm.$apollo.loading)?_c('div',{staticClass:"position-fixed end-0 w-100 justify-content-center",staticStyle:{"background":"rgba(255, 255, 255, 0.8)","height":"100vh","z-index":"1050"}},[_c('div',{staticClass:"text-center col pt-5 mt-5"},[_c('BaseSpinner'),_c('p',{staticClass:"d-block lead fs-4 mt-5"},[_vm._v("Loading tag data")])],1)]):_c('div',{staticClass:"row px-5 pt-4"},[_c('div',{staticClass:"col col-md-8 col-lg-6 col-xl-4"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createTag($event)}}},[_c('h5',[_vm._v(_vm._s(_vm.editingTag.type ? _vm.editingTag.type.label : "Loading"))]),_c('p',{staticClass:"mt-3 mb-5 lead"},[_vm._v(_vm._s(_vm.typeCategoryInfoBlurb))]),(_vm.alert.type)?_c('BaseAlert',{staticClass:"mb-4",attrs:{"type":_vm.alert.type}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.alert.title))]),(typeof(_vm.alert.message) === 'object')?_c('ul',{attrs:{"slot":"message"},slot:"message"},_vm._l((_vm.alert.message),function(m){return _c('li',{key:m},[_vm._v(_vm._s(m))])}),0):_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v(_vm._s(_vm.alert.message))])]):_vm._e(),(_vm.presentFields.label)?_c('BaseFormGroup',{attrs:{"label":'Name'}},[_c('ValidationProvider',{attrs:{"name":"Tag Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","name":"name","error":errors[0],"disabled":!_vm.enabledFields.label},model:{value:(_vm.editingTag.label),callback:function ($$v) {_vm.$set(_vm.editingTag, "label", $$v)},expression:"editingTag.label"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.type)?_c('BaseFormGroup',{attrs:{"label":'Tag Type'}},[_c('ValidationProvider',{attrs:{"name":"Tag Types","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseMultiselect',{attrs:{"options":_vm.tagTypes,"multiple":false,"error":errors[0],"track-by":"name","name":"tagTypes","disabled":!_vm.enabledFields.type},on:{"input":_vm.handleTypeChange},model:{value:(_vm.editingTag.type),callback:function ($$v) {_vm.$set(_vm.editingTag, "type", $$v)},expression:"editingTag.type"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.categories)?_c('BaseFormGroup',{attrs:{"label":'Categories'}},[_c('ValidationProvider',{attrs:{"name":"Tag Categories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseMultiselect',{attrs:{"options":_vm.validCategories,"multiple":true,"error":errors[0],"track-by":"name","name":"tagCategories","disabled":!_vm.enabledFields.categories},model:{value:(_vm.editingTag.categories),callback:function ($$v) {_vm.$set(_vm.editingTag, "categories", $$v)},expression:"editingTag.categories"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.color)?_c('BaseFormGroup',{attrs:{"label":'Colour'}},[_c('ValidationProvider',{attrs:{"name":"Tag Colour"}},[_c('BaseColorPicker',{attrs:{"disabled":!_vm.enabledFields.color},model:{value:(_vm.editingTag.color),callback:function ($$v) {_vm.$set(_vm.editingTag, "color", $$v)},expression:"editingTag.color"}})],1)],1):_vm._e(),(_vm.presentFields.hiddenInFilters)?_c('BaseFormGroup',{attrs:{"label":'Hidden in filter'}},[_c('ValidationProvider',{attrs:{"name":"hiddenInFilters"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"checkbox","name":"hiddenInFilters","error":errors[0],"disabled":!_vm.enabledFields.hiddenInFilters},model:{value:(_vm.editingTag.hiddenInFilters),callback:function ($$v) {_vm.$set(_vm.editingTag, "hiddenInFilters", $$v)},expression:"editingTag.hiddenInFilters"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.hiddenInActivities)?_c('BaseFormGroup',{attrs:{"label":'Hidden in activities'}},[_c('ValidationProvider',{attrs:{"name":"hiddenInActivities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"checkbox","name":"hiddenInActivities","error":errors[0],"disabled":!_vm.enabledFields.hiddenInActivities},model:{value:(_vm.editingTag.hiddenInActivities),callback:function ($$v) {_vm.$set(_vm.editingTag, "hiddenInActivities", $$v)},expression:"editingTag.hiddenInActivities"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.isExternal)?_c('BaseFormGroup',{attrs:{"label":'Is an external channel of communication'}},[_c('ValidationProvider',{attrs:{"name":"isExternal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"checkbox","name":"isExternal","error":errors[0],"disabled":!_vm.enabledFields.isExternal},model:{value:(_vm.editingTag.isExternal),callback:function ($$v) {_vm.$set(_vm.editingTag, "isExternal", $$v)},expression:"editingTag.isExternal"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.isGroupedOnCalendar)?_c('BaseFormGroup',{attrs:{"label":'Channel is grouped on the calendar'}},[_c('ValidationProvider',{attrs:{"name":"isGroupedOnCalendar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"checkbox","name":"isGroupedOnCalendar","error":errors[0],"disabled":!_vm.enabledFields.isGroupedOnCalendar},model:{value:(_vm.editingTag.isGroupedOnCalendar),callback:function ($$v) {_vm.$set(_vm.editingTag, "isGroupedOnCalendar", $$v)},expression:"editingTag.isGroupedOnCalendar"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.shortName)?_c('BaseFormGroup',{attrs:{"label":'Short Name'}},[_c('ValidationProvider',{attrs:{"name":"shortName","rule":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","name":"shortName","error":errors[0],"disabled":!_vm.enabledFields.shortName},model:{value:(_vm.editingTag.shortName),callback:function ($$v) {_vm.$set(_vm.editingTag, "shortName", $$v)},expression:"editingTag.shortName"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.parentLocation)?_c('BaseFormGroup',{attrs:{"label":'Parent Location'}},[_c('ValidationProvider',{attrs:{"name":"Tag Parent Location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseMultiselect',{attrs:{"options":_vm.tagParentLocations,"error":errors[0],"track-by":"name","name":"tagParentLocation","disabled":!_vm.enabledFields.parentLocation},model:{value:(_vm.editingTag.parentLocation),callback:function ($$v) {_vm.$set(_vm.editingTag, "parentLocation", $$v)},expression:"editingTag.parentLocation"}})]}}],null,true)})],1):_vm._e(),(_vm.presentFields.subLocations)?_c('BaseFormGroup',{attrs:{"label":'Child Locations'}},[_c('ValidationProvider',{attrs:{"name":"Tag Children Locations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseMultiselect',{attrs:{"options":_vm.tagChildLocations,"multiple":true,"error":errors[0],"track-by":"name","name":"tagChildrenLocations","disabled":!_vm.enabledFields.subLocations},model:{value:(_vm.editingTag.subLocations),callback:function ($$v) {_vm.$set(_vm.editingTag, "subLocations", $$v)},expression:"editingTag.subLocations"}})]}}],null,true)})],1):_vm._e(),_c('BaseFormGroup',{staticClass:" mt-5 mx-5"},[_c('BaseButton',{attrs:{"type":"submit","disabled":_vm.isLoading,"classes":'btn-success'}},[_c('span',[_vm._v("Save")])]),_c('BaseButton',{staticClass:"btn-secondary mx-5 px-4",attrs:{"disabled":_vm.isLoading,"type":"button"},on:{"click":function($event){return _vm.$router.push({name: 'tags'})}}},[_c('span',[_vm._v("Back")])])],1)],1)]}}])})],1)]),(_vm.user.role.name === 'SYSADMIN')?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body bg-light"},[_c('h5',{staticClass:"card-title"},[_vm._v("SYSADMIN debug")]),_c('p',{staticClass:"card-text"},[_vm._v(" User: "),_c('pre',[_c('code',{staticClass:"text-break"},[_vm._v(_vm._s(_vm.editingTag))])])])])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }